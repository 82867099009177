import { setAbTests } from 'ducks/actions/ab-tests';
import { setAbTestSegments } from 'ducks/actions/segments';
import { updateSettings } from 'ducks/actions/settings';
import { normalizeAbTests } from 'ducks/normalizers/ab-tests';
import { normalizeAbTestSegments } from 'ducks/normalizers/segments';
import { Gemini } from 'utils/axios';
import { replaceQuery } from 'utils/queries';

export const getAdvertiserAbTests = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/advertisers/${entity_id}/ab_tests`);
  const ab_tests = normalizeAbTests(res.data);
  await dispatch(setAbTests(ab_tests));
  return ab_tests;
};

export const getFlightAbTests =
  (flight_id, queries = {}) =>
    async (dispatch) => {
      await dispatch(updateSettings({ loading: true }));

      const query = {
        state_id: 1,
        include_state_ids: [],
        exclude_state_ids: [],
        ...queries,
      };

      const res = await Gemini.get(`/flights/${flight_id}/ab_tests${replaceQuery(query)}`);
      const ab_tests = normalizeAbTests(res.data);
      await dispatch(setAbTests(ab_tests));
      return ab_tests;
    };

export const getChangeOrderAbTests = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/change_orders/${entity_id}/ab_tests`);
  const ab_tests = normalizeAbTests(res.data);
  await dispatch(setAbTests(ab_tests));
  return ab_tests;
};

export const getFlightStrategyAbTests = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/flights/${entity_id}/strategies/ab_tests`);
  const ab_tests = normalizeAbTests(res.data);
  await dispatch(setAbTests(ab_tests));
  return ab_tests;
};

export const createAdvertiserAbTest = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/advertisers/${entity_id}/ab_tests/create`, data);
  return res.data;
};



export const createFlightAbTest = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/flights/${entity_id}/ab_tests/create`, data);
  return res.data;
};

export const updateAbTest = (entity_id, data) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.post(`/ab_tests/${entity_id}/update`, data);
  return res.data;
};

export const getAbTestSegments = (entity_id) => async (dispatch) => {
  await dispatch(updateSettings({ loading: true }));
  const res = await Gemini.get(`/ab_tests/${entity_id}/segments`);
  const ab_test_segments = normalizeAbTestSegments(res.data);
  dispatch(setAbTestSegments(ab_test_segments));
};
